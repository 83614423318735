import { ready } from "./utils.js";
import { Abide } from "../lib/foundation-explicit-pieces.js";
// declare patterns here (not in ready because that will happen after foundation is initialized)
// all default ones can be found here: https://get.foundation/sites/docs/abide.html#builtin-patterns-and-validators
Abide.defaults.patterns["tel"] = /^[0-9]{10}$/;
Abide.defaults.patterns["bestformtag"] =
  /^(Input Text|Input E-Mail|Input Autocomplete \(dieses hier\)|Radio-Buttons|Textarea)$/;

ready(() => {
  const forms = document.getElementsByTagName("form");

  for (let i = 0; i < forms.length; i++) {
    const form = forms[i];
    const allInputs = form.querySelectorAll("input,textarea");

    for (let i = 0; i < allInputs.length; i++) {
      const input = allInputs[i];
      if (input.value.length > 0) {
        input.classList.add("not-empty");
      }

      input.addEventListener("input", ({ target }) => {
        if (target.value.length > 0) {
          target.classList.add("not-empty");
        } else {
          target.classList.remove("not-empty");
        }
      });
    }
  }
});
