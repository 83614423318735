import $ from "jquery";
import "jquery.scrollto";
// npm install jquery.scrollto

$(function() {
    const hash = window.location.hash;
    if(hash.length < 2) {
        return;
    }
    const scrolltarget = hash.substring(1);
    const $scrolltarget = $("[data-scrolltarget="+scrolltarget+"]");
    if($scrolltarget.length > 0) {
        $(window).scrollTo($scrolltarget, 1000);
    }
});

