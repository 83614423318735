import $ from "jquery";

$(function() {
    const pollspeed = 150;
	const $body = $("body");
	
	function updateYScrollClasses() {
		if(window.scrollY < 10) {
			$body.addClass("scrollyeq0");
			$body.removeClass("scrollygt0");
		} else {
			$body.addClass("scrollygt0");
			$body.removeClass("scrollyeq0");
		}
	}
	window.setInterval(updateYScrollClasses, pollspeed);
	updateYScrollClasses();
});