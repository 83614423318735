import $ from "jquery";

$(function() {
    const $ddmenu = $("#ddmenu");
    const $icon = $ddmenu.find(".icon");
    const $menuitems = $ddmenu.find(".menuitems");
    $icon.on("click", function(ev) {
        $menuitems.toggle();
        ev.stopPropagation();
    });
    $(document).on("click", function() {
        $menuitems.hide();
    });
});