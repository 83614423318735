import { ready } from "./utils.js";
ready(() => {
  if ("loading" in HTMLImageElement.prototype) {
    adaptSrcAttribute();
  } else {
    //with library
    loadLazysizesLibrary();
  }
});

function adaptSrcAttribute() {
  const pictures = document.querySelectorAll("picture.lazyload");
  for (let i = 0; i < pictures.length; i++) {
    const pChildren = pictures[i].children;
    for (let j = 0; j < pChildren.length; j++) {
      const child = pChildren[j];

      //determine src or srcset
      const dataSrcset = child.dataset.srcset;
      const dataSrc = child.dataset.src;
      if (dataSrcset) {
        child.srcset = dataSrcset;
      } else {
        child.src = dataSrc;
      }
    }
  }
}

function loadLazysizesLibrary() {
  const script = document.createElement("script");
  script.src =
    "https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.3.2/lazysizes.min.js";
  document.body.appendChild(script);
}
