export function ready(fn) {
    if (document.readyState !== 'loading'){
      fn();
    } else {
      document.addEventListener('DOMContentLoaded', fn);
    }
}
export function getSiblings(element, selector) {
  let siblings = [];
  let targets;
  if (selector)
      targets = element.parentNode.querySelector(':scope > ' + selector)
  else
      targets = element.parentNode.children;

  for (let target of targets) {
      if (target !== element)
          siblings.push(target);
  }
  return siblings;
}
  
export function addEventListenerOnMultipleElements(multipleElements, event, callback) {
  for(let i=0; i<multipleElements.length; i++) {
    const element = multipleElements[i];
    element.addEventListener(event, callback);
  }
}