import $ from "jquery";

$(function() {
	const pollspeed = 250;
	const $totopbutton = $("#totopbutton");
	function updateToTopButton() {
		if(window.scrollY > 600) {
			$totopbutton.addClass("visible");
		} else {
			$totopbutton.removeClass("visible");
		}
	}
	window.setInterval(updateToTopButton, pollspeed);

	$totopbutton.on("click", function() {
		window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
	});
});